import React from 'react';
import ContextMenuItem from './ContextMenuItem';
import { perseusEncode, getSelectedText } from './utils';

const LOGEION_BASE_URL = "https://logeion.uchicago.edu/";
const PERSEUS_BASE_URL = "http://www.perseus.tufts.edu/hopper/resolveform";

function isTextSelected() {
  const selection = window.getSelection();
  return selection.toString().trim().length > 0;
}

function ContextMenu(props) {
  const cntrl = (navigator.platform.slice(0, 3).toLowerCase() === "mac") ? 
    "\u2318" : "^";
  return ( 
    <React.Fragment>              
      {/* <ContextMenuItem
        onClick={this.toggleAllowMargSel.bind(this)}
        >
        <Checkbox
          checked={this.state.allowMargSel}
          // onClick={this.toggleAllowMargSel.bind(this)}
          value="checkedB"
          color="primary"
          inputProps={{
            'aria-label': 'secondary checkbox',
          }}
        />
          Allow marginalia selection
      </ContextMenuItem> */}
      <ContextMenuItem 
        isActiveFcn={isTextSelected} 
        onClick={copyText.bind(this)}
        bottomline
        >
        {`Copy (${cntrl}c)`}</ContextMenuItem>
      {/* <ContextMenuItem reqTextSelection onClick={this.copyBetaCode.bind(this)}>
        Copy beta code</ContextMenuItem> */}
      <ContextMenuItem 
        isActiveFcn={isTextSelected} 
        onClick={openLogeionWindow} 
        >
        Logeion lookup</ContextMenuItem>
      <ContextMenuItem 
        isActiveFcn={isTextSelected} 
        onClick={openPerseusWindow} 
        >
        Perseus lookup</ContextMenuItem>
    </React.Fragment>
  );
}

function copyText() {
  document.execCommand('copy');
  return false;
}

function openPerseusWindow() {
  const selection = getSelectedText();
  const selTxt = selection.toString().trim();
  if (selTxt.length === 0) {
    return;
  }
  const words = selTxt.split(" ");
  const betaCode = perseusEncode(words[0]);
  const url = PERSEUS_BASE_URL + 
    "?type=start&lookup=" + betaCode + "&lang=greek";
  window.open(url);
}

function openLogeionWindow() {
  const selection = getSelectedText();
  const selTxt = selection.toString().trim();
  if (selTxt.length === 0) {
    return;
  }
  const words = selTxt.split(" ");
  const url = LOGEION_BASE_URL + words[0];
  window.open(url);
}

export default ContextMenu;