import React, { Component } from 'react';
import './ChapterPage.css';
// import { textAlign } from '@material-ui/system';

class ChapterPage extends Component {
  constructor(props){
      super(props);
      this.state = {
        lines: []
      };
      this.getChapter();
  }

  memoData = {};
  getChapter() {
    // const memoKey = `${this.props.title}/${this.props.book}/${this.props.chapter}`;
    const memoKey = `${this.props.book}-${String(this.props.chapter).padStart(2, '0')}`;
    if (this.memoData.hasOwnProperty(memoKey)) {
        this.setState({ lines: this.memoData[memoKey] });
        return;
    }
    fetch(`/data/ross${memoKey}.json`)
      .then(res => res.json() )
      .then(lines => {
        lines.sort( (a, b) => (a.bk*10000 + a.ch*100 + a.n*1) 
        - (b.bk*10000 + b.ch*100 + b.n * 1));
        this.setState({ lines: lines });
        this.memoData[memoKey] = lines;
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.title === this.props.title 
        && prevProps.book === this.props.book
        && prevProps.chapter === this.props.chapter) {
            return;
    }
    this.getChapter();
  }

  render() {
      return (
          <div className="greek">
            {this.state.lines.map(line =>
            <span className="sentence" key={line.n}><sup>{line.n}</sup>{line.text} </span>
        )}
          </div>
      );
   }
}
export default ChapterPage;
