import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Select, MenuItem } from '@material-ui/core';
import { booksPerChapter, romanNumerals, greekNumerals } from './utils';

import WithContextMenu from './WithContextMenu';
import ContextMenuContents from './ContextMenuContents';

import ChapterPage from './ChapterPage';
import HgChapterPage from './HgChapterPage';
import './ByChapter.css';

class ByChapter extends Component {
  constructor(){
      super();
      this.state = {
        title: "physica",
        book: 1,
        chapter: 1,
      };
  }

  back = () => {
    let book = this.state.book;
    let newCh = this.state.chapter - 1;
    if (newCh < 1) {
      if (book === 1) {
        return;
      }
      book--;
      newCh = booksPerChapter[book - 1];
    }
    this.setState( { 
      book: book,
      chapter: newCh,
     });
  }

  forward = () => {
    let book = this.state.book;
    let newCh = this.state.chapter + 1;
    if (newCh > booksPerChapter[book - 1]) {
      if (book + 1 > booksPerChapter.length) {
        return;
      }
      newCh = 1;
      book++;
    }
    this.setState( { 
      book: book,
      chapter: newCh,
     });
  }

  selectBook(event) {
    this.setState( {
      book: event.target.value,
      chapter: 1,
    })
  }

  selectChapter(event) {
    this.setState( {
      chapter: event.target.value,
    })
  }

  render() {
      return (
          <div>
            <h1>Book {romanNumerals[this.state.book - 1]}.{this.state.chapter} / {greekNumerals[this.state.book - 1]}.{this.state.chapter}
            </h1>
            <div className="controls"> 
              <Button 
                variant="outlined" 
                color="primary" 
                disabled={this.state.book === 1 && this.state.chapter === 1}
                onClick={this.back}>
                &lt;
              </Button>
              <Select
                value={this.state.book}
                variant="outlined"
                color="primary" 
                autoWidth={true}
                onChange={this.selectBook.bind(this)}
                >
                {romanNumerals.map( (val, i) =>
                  <MenuItem value={i + 1} key={i + 1}>{`${val} (${greekNumerals[i]})`}</MenuItem>
                )}
              </Select>
              <Select
                value={this.state.chapter}
                variant="outlined"
                color="primary" 
                onChange={this.selectChapter.bind(this)}
                >
                {Array.from(Array(booksPerChapter[this.state.book - 1]).keys())
                .map( (val, i) =>
                  <MenuItem value={i + 1} key={i + 1}>{val + 1}</MenuItem>
                )}
              </Select>
              <Button 
                variant="outlined" 
                color="primary" 
                disabled={this.state.book === 8 && this.state.chapter === 10}
                onClick={this.forward}>
                &gt;
              </Button>
            </div>
            <div className="instructions">Highlight and right-click a word to bring up translation tools.</div>
            <table className="sidebyside"><tbody>
              <tr>
                <td>
                <WithContextMenu contents={ContextMenuContents}>
                  <ChapterPage 
                    title={this.state.title} 
                    book={this.state.book} 
                    chapter={this.state.chapter} 
                    />
                  </WithContextMenu>
                </td>
                <td>
                <HgChapterPage 
                  book={this.state.book} 
                  chapter={this.state.chapter} 
                  />
                </td>
              </tr>
              </tbody></table>
          </div>
      );
   }
}
export default ByChapter;
