import React from "react";
import FullScreenButton from "./FullScreenButton";

// fullBody = if true, make document.body go full screen instead of .forFullscreenParent
function WithFullscreenOption(props) {
 const targetEl = React.useRef(null);
 const fullBody = {current: document.body};
  return (
    <div className="forFullscreenParent" ref={targetEl}>
        {props.children}
        <FullScreenButton elem={props.fullBody ? fullBody : targetEl}/>
    </div>
  )
}

export default WithFullscreenOption;
