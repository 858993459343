import React, { Component } from 'react';
// import './ChapterPage.css';

class HgChapterPage extends Component {
  constructor(props){
      super(props);
      this.state = {
        text: ''
      };
      this.getChapter();
  }

  eliminateScripts = txt => {
    return txt.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "");
  }

  memoData = {};
  getChapter() {
    // const memoKey = `${this.props.book}/${this.props.chapter}`;
    const memoKey = `${this.props.book}-${String(this.props.chapter).padStart(2, '0')}`;
    if (this.memoData.hasOwnProperty(memoKey)) {
        this.setState({ text: this.memoData[memoKey] });
        return;
    }
    fetch(`/data/hg${memoKey}.json`)
      .then(res => {
          return res.json();
      })
      .then( x => {
        const text = x.text;
        this.setState({ text });
        this.memoData[memoKey] = text;
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.book === this.props.book
        && prevProps.chapter === this.props.chapter) {
            return;
    }
    this.getChapter();
  }

  render() {
      return (
          <div
          dangerouslySetInnerHTML={{ __html: this.state.text }}
          />
      );
   }
}
export default HgChapterPage;
