import React, { Component } from 'react';
import './App.css';
import SimpleTabs from './SimpleTabs';
import WithFullscreenOption from './WithFullscreenOption'

class App extends Component {
  render() {
      return (
        <WithFullscreenOption fullBody>
          <div className="App">
            <SimpleTabs />
          </div>
        </WithFullscreenOption>
      );
   }
}
export default App;
