import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

class TabPanel extends React.Component {
  state = {
    openedTab: [false, false, false]
  };

  render() {
    const { children, value, index } = this.props;
    const { openedTab } = this.state;
    if (!openedTab[value]) {
      openedTab[value] = true;
      this.setState({ openedTab: openedTab });
    }
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        <Box p={3}>{this.state.openedTab[index] && children}</Box>
      </Typography>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;