/** 
 * 
 * Utilities
 * Methods and constants used across modules
 * 
 **/ 

export const romanNumerals = [ "I", "II", "III", "IV", "V", "VI", "VII", "VIII"];
export const greekNumerals = ["Α", "Β", "Γ", "Δ", "Ε", "Ζ", "Η", "Θ"];
export const booksPerChapter = [ 9, 9, 8, 14, 6, 10, 5, 10];

const betaConvDiacrit = {
    '\u0300': '\\', // grave
    '\u0301': '/', // acute
    '\u0304': '&', // macron
    '\u0306': '\'', // breve
    '\u0308': '+', // diaeresis
    '\u0313': ')', // soft breathing
    '\u0314': '(', // rough breathing
    '\u0342': '=', // circumflex
    '\u0345': '|', // iota sub
    '\u0387': ':', // ano stigme
};

const betaConvUCLetters = {
    "Α": "*A",
    "Β": "*B",
    "Γ": "*G",
    "Δ": "*D",
    "Ε": "*E",
    "Ϝ": "*V",
    "Ζ": "*Z",
    "Η": "*H",
    "Θ": "*Q",
    "Ι": "*I",
    "Κ": "*K",
    "Λ": "*L",
    "Μ": "*M",
    "Ν": "*N",
    "Ξ": "*C",
    "Ο": "*O",
    "Π": "*P",
    "Ρ": "*R",
    "Σ": "*S",
    "Ϲ": "*S", //"*S3",
    "Τ": "*T",
    "Υ": "*U",
    "Φ": "*F",
    "Χ": "*X",
    "Ψ": "*Y",
    "Ω": "*W",
};

const betaConvLCLetters = {
    "α": "A",
    "β": "B",
    "γ": "G",
    "δ": "D",
    "ε": "E",
    "ϝ": "V",
    "ζ": "Z",
    "η": "H",
    "θ": "Q",
    "ι": "I",
    "κ": "K",
    "λ": "L",
    "μ": "M",
    "ν": "N",
    "ξ": "C",
    "ο": "O",
    "π": "P",
    "ρ": "R",
    "σ": "S",
    "ς": "S", //"S2",
    "ϲ": "S", //"S3",
    "τ": "T",
    "υ": "U",
    "φ": "F",
    "χ": "X",
    "ψ": "Y",
    "ω": "W",    
};

const LETTERS_REGEX = /^\p{L}/u;
const DIACRITICS_REGEX = /[\u0300-\u036f]/g;
const VOWEL_LIST = ["α", "ε", "η", "ι", "ο", "υ", "ω", "ᾳ", "ῃ", "ῳ"];
const DIPHTHONG_LIST = ["αι", "ει", "οι", "υι", "αυ", "ευ", "ηυ", "ου"];
const CONSONANT2_LIST = ["βδ", "βλ", "βρ", "γλ", "γν", "γρ", "θλ", "θν", "θρ", "κλ", "κρ", "κτ", "μν", 
  "πλ", "πν", "πρ", "πτ", "σθ", "σκ", "σμ", "σπ", "στ", "σφ", "σχ", "τρ", "φθ", "φλ", "φρ", "χθ",
  "χλ", "χξ", "χρ"];
const CONSONANT3_LIST = ["στρ"];

const makeRegex = strList => `[${strList.join("")}]`;
const VOWEL_REGEX = RegExp(makeRegex(VOWEL_LIST), 'g');

// from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
export function encodeRFC5987ValueChars(str) {
    return encodeURIComponent(str)
        // Note that although RFC3986 reserves "!", RFC5987 does not,
        // so we do not need to escape it
        .replace(/['()]/g, escape) // i.e., %27 %28 %29
        .replace(/\*/g, '%2A')
            // The following are not required for percent-encoding per RFC5987, 
            // so we can allow for a little better readability over the wire: |`^
            .replace(/%(?:7C|60|5E)/g, unescape);
}

export const convertToBetaCode = (utfStr) => {
    const decomp = utfStr.normalize("NFD");
    
    const outArr = decomp.split("").map(glyph => {
        const codept = glyph.codePointAt(0);
        if (codept >= 913 && codept <= 937) {
            return betaConvUCLetters[glyph];
        }
        if (codept >= 945 && codept <= 969) {
            return betaConvLCLetters[glyph];
        }
        if (betaConvDiacrit.hasOwnProperty(glyph)) {
            return betaConvDiacrit[glyph];
        }

        return glyph;
      });
    
    return outArr.join("");
};

export function perseusEncode(txt) {
    return encodeRFC5987ValueChars(convertToBetaCode(txt));
}

export function getSelectedText() {
    if (window.getSelection) {
        return window.getSelection();
    } 
    if (window.document.getSelection) {
        return window.document.getSelection();
    } 
    if (window.document.selection) {
        return window.document.selection.createRange().text;
    }
    return "";  
}

const isLetter = str => LETTERS_REGEX.test(str);
const isVowel = str => VOWEL_REGEX.test(str);
const isConsonant2 = str => CONSONANT2_LIST.includes(str);
const isConsonant3 = str => CONSONANT3_LIST.includes(str);
const isDiphthong = str => DIPHTHONG_LIST.includes(str);

/** Strip the diacritics off the given string  */
function stripDiacritics(str) {
  return str.normalize('NFD').replace(DIACRITICS_REGEX, "");
}

/** 
 * Given a vector `a`, returns a vector with one less element that
 * contains the difference between adjacent lements of `a`.
 */
function diff1(a) {
  return a.slice(1).map( (a0, i) => a0 - a[i]);
}

function insertCharAt(str, char, pos) {
  return `${str.slice(0, pos)}${char}${str.slice(pos)}`;
}

/**
* Given a Greek string, return an array with an element for each
* letter containing its syllable number in the word. Non-letters
* are given a syllable number of 0.
*/
function _numberSyllables(str) {
  const n = str.length;
  const chars = stripDiacritics(str).toLowerCase().split("");

  // number the chars according to their syllables
  const syll = new Array(n); // syllable number for each character
  let nsyl = 0;
  let sylStart = 0;
  chars.forEach( (char, i) => {

    if (!isLetter(char) ) {
      syll[i] = 0;
      if (i > 0 && isLetter(chars[i - 1])) {
        syll[i - 1] = nsyl;
      }
      nsyl = 0;
      sylStart = i + 1;
      return;
    }
    // find the next vowel (there's glitch with isVowell)
    if (!isVowel(char) && !isVowel(char)) {
      return;
    }
    if (typeof syll[i] !== 'undefined') {
      return;
    }
    nsyl++;
    syll[i] = nsyl;
    if (i >= sylStart + 1) {  // previous consonant is always with the present vowel
      const prevChar = chars.slice(i - 1, i).join("");
      if (!isVowel(prevChar)) {
        syll[i - 1] = nsyl;
      }
    }
    if (i >= sylStart + 2) {
      syll[i - 2] = isConsonant2(chars.slice(i - 2, i).join("")) ? nsyl : nsyl - 1;
    }
    if (i >= sylStart + 3) {
      syll[i - 3] = isConsonant3(chars.slice(i - 3, i).join("")) ? nsyl : nsyl - 1;
    }

    sylStart = i + 1;

    // look forward to see if following letter makes a diphthong
    let nextChar = i + 1;
    if (nextChar < n + 1 && isDiphthong(chars.slice(i, i + 2).join("")) ) {
      // console.log("diphthong", chars.slice(i, i + 2));
      syll[nextChar++] = nsyl;
      sylStart++;
    }
    // look forward to see if next character is a sigma
    if (nextChar < n + 1 && chars.slice(nextChar, nextChar + 1).join("") === "ς") {
      syll[nextChar] = nsyl;
      sylStart++;
    }
  }); // end of chars.forEach

  return syll;
}

/**
* Given a Greek string, returns that string with soft hyphens (a.k.a. shy)
* at the syllable breaks. This only works because Greek words are very regular.
*
* @param str - Greek string to be divided into syllables
* @param divChar - optional character to insert between syllables;
*     default is `&shy;`
* @return the original string with `divChar` between syllables
*/
export function syllabificate(str, divChar = "&shy;") {
  const syll = _numberSyllables(str);

  // given the syllable numbers `syll`, find the positions for breaks
  const diff = diff1(syll);
  let pos = [];
  diff.forEach( (d, i) => {
    if (d > 0 && syll[i] > 0) {
      pos.push(i);
    }
  });

  // insert shy into found positions
  let out = str;
  pos.reverse().forEach( p => {
    out = insertCharAt(out, divChar, p + 1);
  });

  return out;

}
