import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import WithContextMenu from './WithContextMenu';
import ContextMenuContents from './ContextMenuContents';

import RossPage from './RossPage';
import styles from './ByRossPage.css';

const MAXPAGE = 215;

class ByRossPage extends Component {
  constructor(props) {
      super(props);
      this.state = {
        page: 1,
        pageInput: 1,
        allowMargSel: false,
        skeuomorph: true,
      };
      this.rosspageEntry = React.createRef();
      this.rosspage = React.createRef();

      this.setPage.bind(this);
  }

  back = () => {
    const newPg = this.state.page - 1;
    if (newPg < 1) {
      return;
    }
    const newVal = newPg < 1 ? 1 : newPg;
    this.rosspageEntry.current.value = newVal;
    this.setState( { page: newVal, pageInput: newPg });
  }

  forward = () => {
    const newPg = this.state.page * 1 + 1;
    if (newPg > MAXPAGE) {
      return;
    }
    const newVal = newPg > MAXPAGE ? MAXPAGE : newPg;
    this.rosspageEntry.current.value = newVal;
    this.setState( { page: newVal, pageInput: newPg });
  }

  setPage(target) {
    const newPg = parseInt(target.value, 10);
    if (isNaN(newPg) || newPg < 1 || newPg > MAXPAGE) {
      target.value = this.state.page;
      return;
    }
    this.setState( { page: newPg, pageInput: newPg });
  }

  // returns a function to toggle state change of the given value
  toggleOnChange(val) {
    return () => {
      let v = {};
      v[val] = !this.state[val];
      this.setState(v);
    }
  }

  render() {
      const controlClasses = [this.state.allowMargSel ? "allowMargSel" : "",
        this.state.skeuomorph ? "skeuomorph" : ""].filter(x=>x).join(" ");
      return (
          <div 
          className={controlClasses}
          ref={this.rosspage}
          >
            <h1>
            W.D. Ross Recension
            </h1>
            <div className="controls">
              <div> 
                Navigate to page: <Button 
                    variant="outlined" 
                    color="primary" 
                    disabled={this.state.page === 1}
                    onClick={this.back}>
                    &lt;
                    </Button>

                <TextField
                  ref={this.rosspageEntry}
                  variant="outlined"
                  color="primary" 
                  className={styles.textField}
                  value={this.state.pageInput}
                  margin="none"
                  inputProps={{
                    style: {
                      textAlign: "center"
                    }
                  }}
                  InputProps={{
                    style: {
                        height: "36px",
                        width: "4em",
                    }
                  }}
                  onKeyPress={ ev => {
                    if (ev.key === 'Enter') {
                      this.setPage(ev.target);
                      ev.preventDefault();
                    }
                  }}
                  onChange = { ev => {
                    this.setState({ pageInput: ev.target.value })
                    }
                  }
                />
                <Button 
                  variant="outlined" 
                  color="primary" 
                  disabled={this.state.page === MAXPAGE}
                  onClick={this.forward}>
                    &gt;
                  </Button>
                </div>
                <div>
                  <label>
                    <input type="checkbox" 
                      checked={this.state.skeuomorph} 
                      onChange={this.toggleOnChange("skeuomorph")} />
                    Skeuomorphic page display?
                  </label>
                  <label>
                    <input type="checkbox" 
                      checked={this.state.allowMargSel} 
                      onChange={this.toggleOnChange("allowMargSel")} />
                    Include line numbers in text selection?
                  </label>
                </div>
            </div>
            <div className="instructions">Highlight and right-click a word to bring up translation tools.</div>
            <div className="display-line">
            <div className={`left-arr ${this.state.page === 1 ? 'disabled': ''}`} 
                onClick={this.back}></div>
            <WithContextMenu contents={ContextMenuContents}>
                <RossPage 
                  page={this.state.page} 
                />
              </WithContextMenu>
              <div className={`right-arr ${this.state.page === MAXPAGE ? 'disabled': ''}`} 
                onClick={this.forward}></div>
              </div>
          </div>
      );
   }
}
export default ByRossPage;
