import React from 'react';
import TabPanel from './TabPanel';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import About from './About';
import ByRossPage from './ByRossPage';
import ByChapter from './ByChapter';
import { Toolbar } from '@material-ui/core';

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));
  
  export default function SimpleTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
  
    function handleChange(event, newValue) {
      setValue(newValue);
    }
  
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              Aristotle's <i>Physics</i>
            </Typography>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs">
            <Tab label="About" {...a11yProps(0)} />
            <Tab label="Ross Pages" {...a11yProps(1)} />
            <Tab label="By Chapter" {...a11yProps(2)} />
          </Tabs>
          </Toolbar>
        </AppBar>
        <TabPanel value={value} index={0}>
          <About/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ByRossPage/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ByChapter/>
        </TabPanel>
      </div>
    );
  }