import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import style from "./About.css";

// const useStyles = makeStyles(theme => ({
//     root: {
//       width: '100%',
//       height: '100%',
//     },
//     heading: {
//       fontSize: theme.typography.pxToRem(20),
//       fontWeight: theme.typography.fontWeightBold,
//       color: "hsl(231, 48%, 30%)",
//     },
//   }));
    
const About = () => {
    // const classes = useStyles();

    return (
  <div className={style.textblock}>
    <details>
      <summary>
        "Old School"?
      </summary>
      <div>
        <p>Why is this site called "Old School Physics"?
        </p>

        <p>Everyone knows "old school" means doing things the rigorous, traditional way. This site 
        is "old school" in seeking to understand nature in the deep way&mdash;the philosophical 
        way&mdash;that preceded the mechanical methods of Descartes. Especially these 
        days when theorists spin out myriad fantasies on the slightest mathematical pretext, we need 
        to return to the <em>physics</em> of physics. Certainly mathematical methods are powerful 
        and instructive, but in the end, physics is not mathematics. As indispensible as mathematics is, 
        it's never a substitute for physical insight. Mathematics is a tool that serves insight. 
        Physics is physics.
        </p>
        
        <p>But this site is "old school" in another way. Before the "Enlightenment," the scholastics 
        or "schoolmen" learned from the texts of Aristotle. This site is "old school" in 
        likewise looking back to Aristotle. It's not that Aristotle has the solution to
        all our problems&mdash;far from it&mdash;simply that we would be wise to evaluate his 
        ideas for ourselves. The so-called Enlightenment was hasty in dismissing 
        him out of hand. Aristotle's conception of nature deserves serious consideration since his 
        philosophical approach draws on an experiential basis broader and in many ways deeper
        than that of controlled experiment.
        </p>
      </div>
    </details>

    <details>
      <summary>
        Aristotle's Physics
      </summary>
      <div>
        <h2>Historical Importance</h2>
        <blockquote>
        The <i>Physics</i> is a lecture in which [Aristotle] seeks to determine beings that arise 
        on their own, τὰ φύσει ὄντα, with regard to their being. Aristotelian "physics" is 
        different from what we mean today by this word, not only to the extent that it belongs to 
        antiquity whereas the modern physical sciences belong to modernity, rather above all it is 
        different by virtue of the fact that Aristotle's "physics" is philosophy, whereas modern 
        physics is a positive science that presupposes a philosophy.[...] This book determines the 
        warp and woof of the whole of Western thinking, even at that place where it, as modern 
        thinking, appears to think at odds with ancient thinking. But opposition is invariably 
        comprised of a decisive, and often even perilous, dependence. Without Aristotle's <i>
        Physics</i> there would have been no Galileo.<sup>1</sup>
        </blockquote>

        <p><a href="https://en.wikipedia.org/wiki/Physics_(Aristotle)" target="_blank" rel="noopener noreferrer">
            More about the <i>Physics</i> at Wikipedia
        </a>.</p>

        <h2>Present Importance</h2>
        <p>The twentieth-century discovery of quantum mechanics made evident the shortcomings of the
            mechanical conception of matter formulated by Descartes and advanced by Newton. So it makes
            sense to look back to the original conception of matter—that of Aristotle—when looking
            for the true nature of matter. Aristotle's conception of matter is definitionally 
            indeterminate, which is precisely the quality that matter in the mechanical conception lacks. 
            As Werner Heisenberg wrote:
        </p>

        <blockquote>
        [Quantum-mechanical probability] is closely related to the concept of possibility, the ‘potentia’ of the 
        natural philosophy of the ancients such as Aristotle; it is, to a certain extent, a transformation of the 
        old ‘potentia’ concept from a qualitative to a quantitative idea.<sup>2</sup>
        </blockquote>

        <hr/>
        <div className={style.notes}>
          <p>Notes</p>
          <p className="bib">1. Martin Heidegger (1991). <i>The Principle of Reason</i>. Studies in Continental 
          Thought. Translated by Lilly, Reginald. Bloomington: Indiana University Press. pp. 62–63.
          </p>
          <p className="bib">2. Werner Heisenberg, “Development of the Interpretation of Quantum Theory”, 
          in <i>Niels Bohr and the Development of Physics</i>, W. Pauli, ed., (New York: McGraw-Hill, 1955), 13.
          </p>
        </div>
      </div>
    </details>

    <details>
      <summary>
        What's to See Here
      </summary>
      <div>
        <p>This site is optimized for Chrome and consists of three parts (tabs):</p>
        <ol>
          <li><b>About</b> (you are here)
          </li>

          <li><b>Ross Pages</b> – a page-by-page display of the standard recension of Aristotle's <i>
              Physics</i> in the original Greek that <em>roughly approximates</em> the layout in the
              printed edition. 
          </li>

          <li><b>By Chapter</b> – a chapter-by-chapter display of the Greek text, along with
          informational tags from the XML file:
          <ul>
              <li>Bekker page numbers ("B:" + page + column a/b),</li>
              <li>Bekker line numbers (simple number; the hyphen indicates whether the line number, 
                  based on the even/odd Ross page, applies to the text to its right/left. Cf. <strong>
                  NB</strong> below), and</li> 
              <li>Ross page number ("p" + number)</li>
          </ul>
          An English translation runs alongside the Greek text.
          </li>
        </ol>

        <p>The tabs with Greek text have a context menu (right-click) for accessing online translation 
            tools for selected text.
        </p>

        <h4>Issues with the Line Numbers</h4>
        <p>The line numbers presented in the Ross Pages are inexact for a number of reasons. 
        </p>
        
        <ul>
          <li>The XML data source keeps each word to a single line instead of following the splitting of words
              across lines in the Ross text.
          </li>
          <li>Your browser is using a different Greek typeface and probably uses a different 
              justification algorithm than Ross's typesetter.
          </li>
          <li>There are also some remaining issues with the JavaScript algorithms I've written; I'm still working 
              on them.
          </li>
        </ul>

        <p>The bottom line is BEWARE: don't take these line numbers too seriously. Most are more or less
            right, but many are wrong.
            It's possible I'm making too much of the line numbers; my Ph.D. is not in classics.
        </p>
      </div>
    </details>


    <details>
      <summary>
        This Web Project
      </summary>
      <div>
        <h2>The Setup</h2>
        <p>This web app is written in React JS using Materials UI components. The data for page and chapter are simple static JSON files.
          (Data used to be stored in a Mongo DB as part of a MERN stack demo project, but that was expensive overkill.)
        </p>

        <h2>The Data</h2>
        <p>The Greek text presented here is taken from the recension by W.D. Ross, 1960 edition<sup>3</sup>. 
            We should be grateful <a href="https://raw.githubusercontent.com/OpenGreekAndLatin/First1KGreek/master/data/tlg0086/tlg054/tlg0086.tlg054.1st1K-grc1.xml" 
            title="XML Source" target="_blank" rel="noopener noreferrer"> 
            this text</a><sup>4</sup>, which was not included in the Perseus library, was finally 
            digitized with some Bekker line information in 2016 as part of 
            the <a href="https://github.com/OpenGreekAndLatin" target="_blank" rel="noopener noreferrer" >
                Open Greek and Latin Project
            </a>'s <a href="http://opengreekandlatin.github.io/First1KGreek/" target="_blank" rel="noopener noreferrer" >
                First One-Thousand Years of Greek Project
            </a> . For good and bad, the XML file is just a direct but rough re-presentation of the layout of the 
            Ross edition, including the Bekker numbers at five-line intervals in the outside margins, but without line break 
            information. As a result, the positions of the Bekker numbers in the text are tied to the oddness 
            and evenness of the pages on which they appear. <strong>NB: For even pages, these numbers mark the the beginning 
            of the line, but for odd pages, they mark the end.</strong> And since the XML contains no information
            on where the line begins, it's not clear from the file where the numbers on odd pages should be moved
            for consistency's sake. In sum, the data in this XML file is best suited to reproducing the Ross pages 
            with rough Bekker numbers, but lacks the flexibility to do much else without assumptions and calculations about
            line breaks.
        </p>

        <p>Nevertheless, in addition to the Ross-page presentation, there is a chapter-by-chapter presentation option,
            which includes the XML file's tag information, as described above. This latter presentation also includes 
            an English translation. The English translation is that of Hardie and Gaye<sup>5</sup>, freely available in 
            many places, but taken 
            from <a href="http://people.bu.edu/wwildman/WeirdWildWeb/courses/wphil/readings/wphil_rdg07_physics_entire.htm" target="_blank" rel="noopener noreferrer" >
            here</a>.
        </p>

        <p>Stay tuned. More to come! 
        </p>

        <hr/>
        <div className={style.notes}>
            <p>Notes</p>
            <p className="bib">3. Aristotle (1936). <i>Aristotle's Physics. A Revised Text with Introduction and Commentary</i>. 
                Translated by Ross, W.D. Oxford: University Press.
            </p>
            <p className="bib">4. Available under the <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" rel="noopener noreferrer">
                    Creative Commons Attribution-ShareAlike 4.0 International License
                </a>.
            </p>
            <p className="bib">5. Aristotle (1930). "Physica". In Ross, W.D. (ed.). <i>The Works of Aristotle</i>. Volume II. 
                Translated by Hardie, R.P.; Gaye, R.K. Oxford: University Press.
            </p>
        </div>
      </div>
    </details>

    <details>
      <summary>
        Who's to Blame
      </summary>
      <div>
        <p>My name is John W. Keck. I have a Ph.D. in Physics from Columbia University. But since around 2012, I've been
            a full-time software engineer. In my spare time I study natural philosophy. I am a fellow 
            of <a href="http://www.isnature.org/" target="_blank" rel="noopener noreferrer">
                The Institute for the Study of Nature</a> My recent paper might be of interest:
        </p>

        <p className="bib">John W. Keck (2022). Maximal motion and minimal matter: Aristotelian physics and special relativity. 
            <i>Synthese</i> 200, 377. <a href="https://doi.org/10.1007/s11229-022-03771-2" target="_blank" rel="noreferrer">https://doi.org/10.1007/s11229-022-03771-2</a>
        </p>


        <p>You can hold me accountable through my yahoo mail account: jwkeck15.
        </p>
      </div>
    </details>

    <details>
      <summary>
        Also Helpful
      </summary>
      <div>
        <p>The DHS web version of <a href="https://dhspriory.org/thomas/Physics.htm" target="_blank" rel="noopener noreferrer">
            Aquinas's commentary on Aristotle's <i>Physics</i></a> includes a useful parallel 
            presentation of Aristotle's Greek alongside the Hardie-Gaye translation, which includes the 
            Bekker page+column numbers. UNFORTUNATELY the link is dead, but you can still use the URL 
            to find the commentary elsewhere, if you know where to look.
        </p>

        <p>Two English translations known to be helpful:</p>

        <p className="bib">Aristotle (2005). <i>Physics, or, Natural Hearing</i>. 
        Translated by Coughlin, Glen. South Bend: St. Augustine’s Press. 
        <em>Uses what has become over the centuries standard terminology, and is alive to the issues
            in natural philosophy that are still relevant today. The introduction is an excellent
            explanation of the relevance of the <i>Physics</i> to the modern, scientific world.</em> 
        </p>
        <p className="bib">Aristotle (1995). <i>Aristotle's Physics: A Guided Study</i>. 
        Translated by Sachs, Joe. New Brunswick, NJ: Rutgers University Press.
        <em>Tends to use non-standard terminology to bring out the novelty of Aristotle's positions;
            can come across as awkward, but can sometimes be quite illuminating.</em>
        </p>

        <p>For help with Greek, the <a href="https://alpheios.net/" target="_blank" rel="noopener noreferrer">
          Alpheios Project</a> browser plugin is a great aid in exploring the text.
        </p>

        <div className="subsection">Resources for understanding the <i>Physics</i></div>
        
        <p className="bib">Connell, Richard J. (1966). <i>Matter and Becoming</i>. Chicago: Priory Press. 
            <em>Helpful for understanding <i>Physics</i>, book I.
            </em>
        </p>

        <p className="bib">David L. Schindler (1986) "The Problem of Mechanism" in <i>Beyond Mechanism</i>, ed. David L. Schindler 
        (University Press of America, 1986), 1-12 at 3-4.
        <em>The importance of the concept of nature vs. matter (Physics II.1-2, 7-9) and its relation to modern conceptions 
            of mind and matter. 
            You might need to re-read it a few times.
        </em>
        </p>

        <p className="bib">Mortimer Adler (1978) <i>Aristotle for Everybody: Difficult Thought Made Easy</i>.
        <em>Supremely clear explanation of Aristotle's thought, most notably the chapter on the four causes (Physics II.3).</em>
        </p>

        <p className="bib">Michael J. Dodds (2001) "Science, Causality And Divine Action: Classical Principles For 
            Contemporary Challenges," <i>CTNS Bulletin</i> 21.1 (Winter 2001), sect. 2-3.
            <em>How the four causes of Physics II.3 relate to causes in modern science.</em>
        </p>

        <p className="bib">Rémi Brague (1990). Trans. Pierre Adler; Laurent d'Ursel. "Aristotle's Definition 
            of Motion and Its Ontological Implications". <i>Graduate Faculty Philosophy Journal</i>. 
            13 (2): 1–22. doi:10.5840/gfpj19901321.
            <em>Excellent explanation of the definition of motion in Physics III.1.</em>
        </p>

        <p className="bib">John W. Keck (2022). Maximal motion and minimal matter: Aristotelian physics and special relativity. 
            <i>Synthese</i> 200, 377. <a href="https://doi.org/10.1007/s11229-022-03771-2" target="_blank" rel="noreferrer">https://doi.org/10.1007/s11229-022-03771-2</a>. 
            <em>Section 2.2 explains the definition of motion. The paper shows the relevance of Aristotle's <i>Physics</i> for modern physics.</em>
        </p>
      </div>
    </details>
  </div>
    );
}

export default About;