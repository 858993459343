import React from "react";
import './WithContextMenu.css';
import { SCROLLABLE } from './FullScreenButton';

/* 
 * Context menu component, but limited to a given area of screen
 *
 * Pressing the context-menu button (right mouse button) in the elements enclosed
 * by this context-menu component causes the context menu to become visible.
 * 
 * The contents of the menu are supplied by the `contents` argument, which is a
 * `React.Fragment` grouping `ContextMenuItem`s.
 * 
 * NB: using `stopPropagation` on any `ContextMenuItem` action handler will
 * prevent the menu from closing on item selection.
 * 
 * @author John W. Keck
 * @since August 2019
 */

const EX = 6; // vertical font size, pts

class WithContextMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            x: 0,
            y: 0,
            overflows: null, // so scroll freeze plays nice with fullscreen
        };
        this.menuEl = React.createRef();
    }

    openContextMenu(event) {
        event.preventDefault();
        let clickX = event.pageX - window.pageXOffset;
        let clickY = event.pageY - window.pageYOffset + 2 * EX;
        this.setState({ 
            x: clickX, 
            y: clickY,
        });

        if (this.state.visible) {
          return;
        }
        
        this.setState({ 
          visible: true, 
        });

        window.addEventListener('keydown', this.closeOnEscapeKeypress(this), false);

        const scrollVals = new Map();
        scrollVals.set(document.body, document.body.style.overflow);
        document.querySelectorAll("." + SCROLLABLE).forEach( node => {
          scrollVals.set(node, node.style.overflow);
        });
        this.setState({ overflows: scrollVals });
        scrollVals.forEach( (v, k) => {
          k.style.overflow = "hidden";
        } );

 
        // next click, whether inside the menu or outside, needs to close the menu
        document.addEventListener('click', this.closeMenu.bind(this));
    }

    componentWillUnmount() {
        this.closeMenu();
        window.removeEventListener('contextmenu', this.openContextMenu.bind(this));
    }

    closeOnEscapeKeypress(self) {
        return function (e) {
            if (e.keyCode === 27) {
                self.closeMenu();
            }
        };
    }

    closeMenu() {
        this.setState({ visible: false, x:0, y:0 });
        window.removeEventListener('keydown', this.closeOnEscapeKeypress(this), false);
        document.removeEventListener('click', this.closeMenu.bind(this));
        this.state.overflows.forEach( (v, k) => {
          k.style.overflow = v;
        } );
    }

    preventDefault(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        return false;
    }

    noContextMenuFromContextMenu(event) {
        if (event.target === this.menuEl.current) {
            return;
        }
        event.preventDefault();
        this.closeMenu();
    }

    // need to adjust x,y so menu is completely within the document body
    componentDidUpdate() {
        if (!this.menuEl || !this.menuEl.current 
          || typeof this.menuEl.current === 'undefined') {
            return;
        }
        const el = this.menuEl.current.getBoundingClientRect();
        let pageX = this.state.x, 
            pageY = this.state.y;
        // const pWidth = document.body.clientWidth,
        //     pHeight = document.body.clientHeight;
        const pWidth = window.innerWidth,
            pHeight = window.innerHeight;

        if (pageX + el.width <= pWidth 
            && pageY + el.height <= pHeight) {
            return;
        }
        if (pageX + el.width > pWidth) {
            pageX -= el.width;
        }
        if (pageY + el.height > pHeight) {
            pageY -= el.height + 4 * EX;
        }
        this.setState({ x: pageX, y: pageY });
    }

    render() {
        const myStyle = {
            'position': 'fixed',
            'top': `${this.state.y}px`,
            'left':`${this.state.x}px`,
            z: 999,
        }

        return (<React.Fragment>
            <div onContextMenu={this.openContextMenu.bind(this)}>
                {this.props.children}
            </div>
            { this.state.visible && 
              <div className='custom-context' 
                  style={myStyle}
                  onContextMenu={this.noContextMenuFromContextMenu.bind(this)}
                  ref={this.menuEl}
              >
                  {new this.props.contents()}
              </div> 
            }
        </React.Fragment>)
    }
}

export default WithContextMenu;
