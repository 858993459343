import React, { Component } from 'react';
import { greekNumerals, syllabificate } from './utils';
import './RossPage.css';

const greekChapters_ch = [...greekNumerals, "Η (textus alter)"];
const greekChapters = [...greekNumerals, "Η&prime;"];
const FONTSIZE_INCR = 0.1;

class RossPage extends Component {
  constructor(props){
      super(props);
      this.state = {
        text: '',
      };
      this.getPage();
      this.rossTxt = React.createRef();
  }

  eliminateScripts(txt) {
    return txt.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "");
  }

  markupTextToHtml(head, txt0) {
    const evenOrOdd = this.props.page % 2 === 0 ? "even" : "odd";
    let txt = this.eliminateScripts(txt0);
    let before = "", after = "";
    if (evenOrOdd === 'even') {
      before = "</span></div><div class='chunk'>";
      after = "<span>";
    } else {
      before = "</span>";
      after = "</div><div class='chunk'><span>";
    }

    txt = txt
      .replace(/<B:23, 227a;>/, "<B:23,227a>") // book Epsilon (5) shenanigans
      .replace(/<B:10,226b><26>/, "<B:10,226b26>") // book Epsilon (5) shenanigans
      .replace(/<35[:-\d]*>\s*<B:242a>/, "<B:242a35>") // book Eta (7) shenanigans
      .replace(/<B:242b><35>/, "<B:242b35>") // book Eta (7) shenanigans
      .replace(/<bk(\d+)>/g, 
        (match, p1) => "</div><div class='book'>Book " + greekChapters_ch[p1 - 1])
      .replace(/<ch(\d+)>/g, 
        "</span></div><div class='chunk'><div class='chapter margNote'>$1</div><span>&nbsp;&nbsp;")
      .replace(/<B:([\w,]+)>/g, 
        before + "<div class='bekkerPage margNote'>$1</div>" +  after)
      .replace(/<(\d+[,-\s–]*\d*)>/g, 
        before + "<div class='bekkerLine margNote'>$1</div>" + after)
      ;

      if (evenOrOdd === 'even') {
        txt = txt
          .replace(/<(\d+):(\d+)>/g, 
            "</span></div><div class='chunk' style='text-indent:$2ex;'><div class='bekkerLine margNote'>$1</div><span>")
      } else {
        txt = txt
          .replace(/<(\d+):(\d+)>/g, // leave indentation to JS
            "</span><div class='bekkerLine margNote dup' data-diff='$2'>$1</div></div><div class='chunk'><span>")
      }

    txt = "<div class='" + evenOrOdd + "'>"
      + "<div class='header'>" + head + "</div>"
      + "<div class='init chunk'><span>" + txt + "</span></div>"
      + "<div class='pageNum'>" + this.props.page + "</div>"
      + "</div>";

    return txt.replace(/<div>\s*<\/div>/g,"");
  }

  doOddIndentation() {
    const el = this.rossTxt.current;
    const elems = el.getElementsByClassName("dup");
    [].forEach.call(elems, v => {
      // const wd = v.dataset.diff;
      // const indent = wd > 57 ? 0 : 57 - wd;
      // v.setAttribute("style", "text-indent: 0;");
      // v.parentElement.setAttribute("style", "text-indent: " + indent + "ex;");
      v.parentElement.setAttribute("style", "text-align: right;");
    });
  }

  howManyLinesIsBest(divEl) {
    const rect0 = (divEl.getClientRects())[0];
    const width = rect0.width;
    const span = (divEl.getElementsByTagName("span"))[0];
    const lines = {};
    [].forEach.call(span.getClientRects(),
      v => {
        if (lines.hasOwnProperty(v.y)) {
          lines[v.y] += v.width;
          return;
        }
        lines[v.y] = v.width;
      }
    );
    const wideRects = Object.values(lines).filter(v => v > width/2);
    return wideRects.length > 1 ? wideRects.length : 1;
  }

  adjustFontSizeToFitBlock() {
    const el = this.rossTxt.current;
    const elems = el.getElementsByClassName("chunk");
    [].forEach.call(elems, v => {
      const optimalLines = this.howManyLinesIsBest(v);
      let ht = v.offsetHeight;
      const styleNow = window.getComputedStyle(v);
      let lh = styleNow.lineHeight.slice(0,-2);
      let fs = styleNow.fontSize.slice(0,-2);
      const fsLimit = fs * 0.9;
      const doAlign = ht/lh > optimalLines;
      while (ht/lh > optimalLines && fs > fsLimit) {
        v.style.fontSize = (fs - FONTSIZE_INCR) + "px"; 
        ht = v.offsetHeight;
        lh = styleNow.lineHeight.slice(0,-2);
        fs = styleNow.fontSize.slice(0,-2);  
      }
      if (doAlign) {
        v.style.textAlignLast = "justify";
        const notes = v.getElementsByClassName('margNote');
        if (notes.length > 0) {
          const note = notes[0];
          const adjuster = note.classList.contains("chapter") ? 1.5 : 0.75;        
          note.style.fontSize = (fsLimit / 0.9 * adjuster) + "px" ;
        }
      }
    });
  }

  memoByBekkerPg = {}
  memoText = {};
  getPage() {
    const memoKey = `${this.props.page}`.padStart(3, "0");
    if (this.memoText.hasOwnProperty(memoKey)) {
        this.setState({ 
          text: this.memoText[memoKey],
        });
        return;
    }
    fetch(`/data/ross${memoKey}.json`)
      .then( res => res.json() )
      .then( res => {
        let head = "";
        let text = syllabificate(res.text);
        const {bk0, bk1, ch0, ch1, pb0, pb1} = res;
        head = greekChapters[bk0 - 1] + "." + ch0;
        if (bk0 !== bk1) {
          head += "&ndash;" + greekChapters[bk1 - 1] + "." + ch1;
        }
        else {
          if (ch0 !== ch1) {
            head += "&ndash;" + ch1;
          }
        }

        head += ", " + pb0;
        if (pb0 !== pb1) {
          if (pb0.slice(0,-1) === pb1.slice(0, -1)) {
            head += "&ndash;b"
          }
          else {
            head += "&ndash;" + pb1;
          }
        }
        text = this.markupTextToHtml(head, text);
        this.setState({ text });
        if (this.props.page %2 === 1) {
          this.doOddIndentation();
        }
        this.adjustFontSizeToFitBlock();
        this.memoText[memoKey] = this.rossTxt.current.innerHTML;
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page === this.props.page) {
      return;
    }
    this.getPage();
  }

  render() {
      return (
        <div className="textblock greek"
          ref={this.rossTxt}
          dangerouslySetInnerHTML={{ __html: this.state.text }}>
        </div>
      );
   }
}
export default RossPage;
