/** 
 * ContextMenuItem, part of `WithContextMenu`
 *
 * Adapts materials from
 * https://medium.com/@subalerts/https-medium-com-implementing-custom-context-menu-in-react-js-part-1-b103260c724c
 *
 * PROPS
 * children : text of the item
 * isActiveFcn : function that evaluates to tell whether the item should be shown as active
 * onClick : function executed when the item is clicked
 * bottomline : if true/included, follow this item with a horizontal separation rule
 */

import React from 'react';

let childIndex = -1;

function ContextMenuItem(props) {
  const classNames = ["custom-context-item"];
  if (props.bottomline) {
      classNames.push("bottomline");
  }
  if (props.isActiveFcn && !props.isActiveFcn()) {
      classNames.push("inactive");
  }
  const index = childIndex++;
  return (
    <div key={index} 
      onClick={e => {
          e.preventDefault();
          props.onClick();
      }} 
    className={classNames.join(" ")}>
      {props.children}</div>
  );
}


export default ContextMenuItem;